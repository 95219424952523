import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_APP } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import AccountPopover from '../header/AccountPopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};

  return (
    <Link
      underline="none"
      color="inherit"
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />
        <Box sx={{ ml: 1, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {userDetails?.data?.user?.name || 'username'}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {userDetails?.data?.user?.user_type}
          </Typography>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <AccountPopover />
        </Box>
      </RootStyle>
    </Link>
  );
}
