// eslint-disable-next-line import/no-unresolved
// import WebhookIcon from '@mui/icons-material/Webhook';
import { PATH_APP, PATH_CRM } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';



const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  addUser : getIcon('ic_user'),
  groups : getIcon('ic_groups')
};

const NavCRMConfig = [
  {
    subheader: 'CRM',
    items: [
      { title: 'Contacts', path: PATH_CRM.users, icon: ICONS.addUser },
    ],
  }
  
  // { 
  // items: [
  //    { title: 'Groups', path: PATH_CRM.groups, icon: ICONS.groups },
  //  ],
  //},
];

export default NavCRMConfig;
