// eslint-disable-next-line import/no-unresolved
// import WebhookIcon from '@mui/icons-material/Webhook';
import { PATH_APP,  } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';


const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  campaign: getIcon('ic_campaign'),
  template: getIcon('ic_template'),
  settings: getIcon('ic_settings'),
  report: getIcon('ic_report'),
  auditor: getIcon('ic_auditor'),
  channels: getIcon('ic_channels'),
  department: getIcon('ic_department'),
  agents: getIcon('ic_agents'),
  webhook: getIcon('ic_webhook'),
  organization: getIcon('ic_organization'),
  addUser : getIcon('ic_addUser')
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      { title: 'Home', path: PATH_APP.general.dashboard, icon: ICONS.dashboard },
      { title: 'Organization', path: PATH_APP.general.organization, icon: ICONS.department },
      { title: 'Workflow', path: PATH_APP.general.workflow, icon: ICONS.kanban },
      // { title: 'Channels', path: PATH_APP.general.channels, icon: ICONS.channels },
      { 
        title: 'Analytics', 
        path: PATH_APP.general.analytics, 
        icon: ICONS.analytics,
        children: [
          { title: 'Dashboard', path: PATH_APP.general.analytics},
          { title: 'WA Failed messages', path: PATH_APP.general.wafailed},
          // { title: 'Conversation Report', path: PATH_APP.general.conversation},
          // { title: 'Chat history', path: PATH_APP.general.chathistory},
        ],
      
      }
      // { title: 'Reports', path: PATH_APP.general.reports, icon: ICONS.report },
    ],

  },

  // MANAGEMENT
  {
    subheader: 'management',
    items: [
      { title: 'Departments', path: PATH_APP.management.departments, icon: ICONS.department },
      { title: 'Agents', path: PATH_APP.management.agents, icon: ICONS.agents },
      // { title: 'Auditors', path: PATH_APP.management.auditors, icon: ICONS.auditor },
      {
        title: 'Templates',
        path: PATH_APP.management.templates,
        icon: ICONS.template,
        children: [
          { title: 'WA Templates', path: PATH_APP.management.waTemplates },
        ],
      },
      { title: 'Campaigns', path: PATH_APP.management.campaigns, icon: ICONS.campaign },
      { title: 'Business Settings', path: PATH_APP.management.businessSettings, icon: ICONS.settings },
      { title: 'Webhook & API', path: PATH_APP.management.webhookAPI, icon: ICONS.invoice },
      { title: 'Chat', path: PATH_APP.management.chat, icon: ICONS.chat },
    ],
  },
];

export default navConfig;
