import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// components
import { IconButtonAnimate } from '../../../components/animate';
import ClearIcon from '@mui/icons-material/Clear';
// ----------------------------------------------------------------------

ExitButton.propTypes = {
  collapseClick: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
};

export default function ExitButton({ onToggleCollapse, exitCLick }) {
  return (
    <IconButtonAnimate onClick={exitCLick}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(exitCLick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        <ClearIcon/>
      </Box>
    </IconButtonAnimate>
  );
}

// ----------------------------------------------------------------------

