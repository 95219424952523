import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import backgroundImage from '../../assets/bot.png'; // Path to your background image
import { PATH_AUTH } from '../../routes/paths';


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  background: 'linear-gradient(277deg, rgba(255,255,255,1) 41%, rgba(0,171,85,1) 100%)'})

  
const StyledButton = styled(Button)({
    margin: '8px',
    padding: '12px 24px',
    width: '200px',
    borderRadius: '0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
  });

  const ImageContainer = styled('div')({
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
  
  const Image = styled('img')({
    width: '90%',
    borderRadius: '10px',
});

const ButtonContainer = styled('div')({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  });


const MinimalTemplate = () => {
  const naviagte = useNavigate()
  const redirectTo = (page) =>  {
      naviagte(PATH_AUTH[page])
  }
  return (
    <Container>
      <Grid container>
          <Grid item md={6}>
          <ImageContainer>
            <Image src={backgroundImage} alt="Background" />
          </ImageContainer>
          </Grid>
          <Grid item md={6}>
            <ButtonContainer>
              <StyledButton variant="contained" color="primary" onClick={() => redirectTo('login')}>
                  Login
              </StyledButton>
              <StyledButton variant="contained" color="secondary" onClick={() => redirectTo('register')}>
                Sign Up
              </StyledButton>
            </ButtonContainer>
          </Grid>
      </Grid>
    </Container>
  );
}

export default MinimalTemplate;
