import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Switch, IconButton, Avatar } from '@mui/material';
// routes
import { PATH_APP, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MenuPopover from '../../../components/MenuPopover';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import createAvatar from '../../../utils/createAvatar';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Dashboard',
    linkTo: '/app/general/dashboard',
  },
  {
    label: 'CRM',
    linkTo: '/crm/contacts',
  }
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const userData = useSelector((state) => state?.auth?.user?.data);
  const userRole = userData?.user?.user_type;
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  const { onHoverLeave } =
  useCollapseDrawer();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
      onHoverLeave(); // to collapse the navbarvertical(side drawer) on logout
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(Boolean(anchorEl) && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.8),
            },
          }),
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <MenuPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        arrow="bottom-right"
        sx={{ mt: -6.3, width: 350 }}
      >
        {
          userRole !== 'AGENT' && userRole !== 'AUDITOR' &&
          <>
            <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: 'primary.main', color: 'white' }}>O</Avatar>
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle1" noWrap>
                  ODIObot
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  https://www.odiobot.com/
                </Typography>
              </Box>
            </Box>
            <Box sx={{ p: 0.5 }}>
              <Typography variant="body2">Account Details</Typography>
            </Box>

            <Box sx={{ p: 0.5 }}>
              <Typography variant="body2">Users & roles</Typography>
            </Box>
            <Divider />
          </>
        }

        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
          <Avatar
            src={user?.photoURL}
            alt={user?.displayName}
            color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}

          >
            {createAvatar(user?.displayName).name}
          </Avatar>
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle1" noWrap>
              {userDetails?.data?.user?.name || 'username'}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {userDetails?.data?.user?.email || 'user@example.com'}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 0.5 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Push Notification</Typography>
            <Switch />
          </Stack>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            Failed please retry? <RouterLink to="#">Need Help?</RouterLink>
          </Typography>
        </Box>

        {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            {MENU_OPTIONS.map((option) => (
              <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose} sx={{ p: 0.5 }}>
                {option.label}
              </MenuItem>
            ))}
            <Divider sx={{ borderStyle: 'dashed' }} style={{ marginTop: '1px', marginBottom: '1px' }} />
          </>
        )}
        <MenuItem onClick={handleLogout} sx={{ p: 0.5 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
